/* eslint no-console: ["error", { allow: ["warn", "error"] }] */

import Inputmask from 'inputmask';
import axios from 'axios';

const token = document.querySelector('meta[name="csrf-token"]').content;
axios.defaults.headers.common['X-CSRF-Token'] = token;


function validate(input) {
    const re_tel = /^\+38 \(0\d{2}\) \d{3} - \d{2} - \d{2}$/;
    const re_mail = /\S+@\S+\.\S+/;

    if (input.name === 'add_tel') {
        return re_tel.test(input.value)
                || input.value === input.dataset.placeholder
                || input.value === '';
    }

    if (input.name === 'add_mail') {
        return re_mail.test(input.value) || input.value === '';
    }

    return false;
}


function check_add_data(event) {
    const btn = event.target;
    const input = btn.closest('.form-point').querySelector('input');

    if (input.disabled) {
        input.disabled = false;
        input.placeholder = '';
        input.focus();
        return;
    }

    input.disabled = true;
    input.placeholder = input.dataset.placeholder;

    if (validate(input)) {

        if (input.value === input.dataset.placeholder) {
            input.value = '';
        }

        axios.post(`/${input.name}`, {
            type: input.type,
            value: input.value,
        })
        .then(function () {
            // empty
        })
        .catch(function (error) {
            console.error(error);
        });

    } else {
        input.classList.add('form-input_error');
    }
}

export default function (){
    const btns = document.querySelectorAll('.form-icon-btn_pencil');
    const inputs = document.querySelectorAll('.form-input_edit');
    const tel = document.querySelector('#add_tel');

    for(let btn of btns) {
        btn.addEventListener('click', check_add_data);
    }

    for(let input of inputs) {
        input.addEventListener('focus', ev => ev.target.classList.remove('form-input_error'));
    }

    if (tel) {
        Inputmask({
            mask: '+38 (099) 999 - 99 - 99',
            placeholder: 'X',
        }).mask(tel);
    }
}

/* eslint consistent-this: ["error", "self"] */
/* eslint no-console: ["error", { allow: ["warn", "error"] }] */

import Vue from 'vue/dist/vue.min.js';
import axios from 'axios';
import queryString from 'query-string';
import Cookies from 'js-cookie';

export default function() {
    const root_id = 'auth_reg_body';
    const re_email = /\S+@\S+\.\S+/;

    if (!document.querySelector(`#${root_id}`)) {
        return;
    }

    const token = document.querySelector('meta[name="csrf-token"]').content;
    axios.defaults.headers.common['X-CSRF-Token'] = token;

    const screen_list = [
        'enter_email',
        'enter_password',
        'enter_new_password',
        'text_new_user',
    ];

    const auth = new Vue({
        el: `#${root_id}`,
        template: '#form_template',
        data: function () {
            return {
                screen: screen_list[0],
                show_error: false,
                email: '',
                password: '',
                reg_pass_one: '',
                reg_pass_two: '',
                // messages
                auth_email_message: '',
                auth_pass_message: '',
                secret: '',
            };
        },
        methods: {
            send_email: function(ev) {
                const self = this;
                const action = ev.target.dataset.action;
                self.show_error = false;

                if (re_email.test(self.email)) {

                    axios.post(action, {
                        email: self.email,
                    })
                    .then(function (response) {
                        const data = response.data;
                        if (data.status) {
                            self.screen = data.active ? 'enter_password' : 'text_new_user';
                        } else {
                            self.auth_email_message = data.message;
                            self.show_error = true;
                        }
                    })
                    .catch(function (error) {
                        console.error(error);
                    });

                } else {
                    self.show_error = true;
                }
            },
            send_passwd: function(ev) {
                const self = this;
                const action = ev.target.dataset.action;
                self.show_error = false;

                axios.post(action, {
                    password: self.password,
                })
                .then(function (response) {
                    const data = response.data;

                    if (data.status) {

                        if (Cookies.get('redirect')) {
                            window.location = Cookies.get('redirect');
                            Cookies.remove('redirect');
                        } else {
                            window.location = '/';
                        }

                    } else {
                        self.auth_pass_message = data.message;
                        self.show_error = true;
                    }
                })
                .catch(function (error) {
                    console.error(error);
                });
            },
            set_passwd: function(ev) {
                const self = this;
                const action = ev.target.dataset.action;
                self.show_error = false;

                if (self.reg_pass_one !== self.reg_pass_two || self.reg_pass_one === '') {
                    self.show_error = true;

                } else {
                    axios.post(action, {
                        reg_pass_one: self.reg_pass_one,
                        reg_pass_two: self.reg_pass_two,
                        secret: self.secret,
                    })
                    .then(function (response) {
                        const data = response.data;

                        if (data.status) {

                            if (Cookies.get('redirect')) {
                                window.location = Cookies.get('redirect');
                                Cookies.remove('redirect');
                            } else {
                                window.location = '/';
                            }

                        } else {
                            self.auth_pass_message = data.message;
                            self.show_error = true;
                        }
                    })
                    .catch(function (error) {
                        console.error(error);
                    });
                }


            },
            hide_placeholder: function(ev) {
                ev.target.placeholder = '';
            },
            show_placeholder: function(ev) {
                ev.target.placeholder = ev.target.dataset.placeholder;
            },
        },
    });

    // Show / Hide events
    // const login_buttons = document.querySelectorAll('[data-type="login-button"]');
    const close_buttons = document.querySelectorAll('.popup-close');
    const shadow = document.querySelector('.shadow');
    const popup_box = document.querySelector('.popup-box');

    function show_auth_popup() {
        const scroll = window.pageYOffset || document.documentElement.scrollTop;
        popup_box.style.top = `${scroll + 20}px`;
        document.body.classList.add('show_shadow', 'show_popup');
    }

    function hide_auth_popup() {
        document.body.classList.remove('show_shadow', 'show_popup');
        auth.screen = screen_list[0];
    }

    document.addEventListener('click', (ev) => {
        if (ev.target.dataset.type === 'login-button'){
            ev.preventDefault();
            if (ev.target.dataset.redirectUrl) {
                Cookies.set('redirect', ev.target.dataset.redirectUrl);
            }
            show_auth_popup();
        }
    });

    // for(let btn of login_buttons) {
    //     btn.addEventListener('click', (ev) => {
    //         ev.preventDefault();
    //         if (ev.target.dataset.redirectUrl) {
    //             Cookies.set('redirect', ev.target.dataset.redirectUrl);
    //         }
    //         show_auth_popup();
    //     });
    // }

    for(let btn of close_buttons) {
        btn.addEventListener('click', (ev) => {
            ev.preventDefault();
            hide_auth_popup();
        });
    }

    if (shadow) {
        shadow.addEventListener('click', () => {
            hide_auth_popup();
        });
    }

    const params = queryString.parse(location.search);
    if ('secret' in params) {
        auth.screen = screen_list[2];
        auth.secret = params.secret;
        show_auth_popup();
    }

}

import trim from 'lodash/trim';

export default function() {
    const SEARCH_CSS = 'show-seach-form';
    const btn = document.querySelector('.btn_search');
    const body = document.body;
    const form = document.querySelector('.search-form');

    if (!btn) {
        return;
    }

    btn.addEventListener('click', () => {
        if (body.classList.contains(SEARCH_CSS)) {
            body.classList.remove(SEARCH_CSS);
        } else {
            body.classList.add(SEARCH_CSS);
        }
    });

    document.addEventListener('click', (ev) => {
        const target = ev.target;
        if (target !== btn && !target.closest('.search-box')) {
            body.classList.remove(SEARCH_CSS);
        }
    });

    if (form) {
        form.addEventListener('submit', (ev) => {
            const value = form.q.value;

            if(trim(value, '_- ').length === 0) {
                ev.preventDefault();
            }
        });
    }
}

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs").start()
// require("@rails/activestorage").start()
// require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import search_form from './search_form';
import login_popup from './login_popup';
import init_quiz from './quiz';
import init_profile_add from './profile_add_data';
import get_more from './get_more';

document.addEventListener('DOMContentLoaded', () => {
    search_form();
    login_popup();
    init_quiz();
    init_profile_add();
    get_more();

    const sandwich = document.querySelector('.sandwich-button');
    if (sandwich) {
        sandwich.addEventListener('click', (e) => {
            const header = e.target.closest('.header');
            if (!header) {
                return;
            }

            if (header.classList.contains('header_nav-opened')) {
                header.classList.remove('header_nav-opened');
            } else {
                header.classList.add('header_nav-opened');
            }
        });
    }
});

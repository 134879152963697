/* eslint no-console: ["error", { allow: ["warn", "error"] }] */

export default function(param) {
    let valid = true;
    let questions;

    if (typeof param === 'number') {
        questions = document.querySelectorAll(`.section_${param} .question-item`);
    } else if (typeof param === 'string') {
        questions = document.querySelectorAll('.question-section .question-item');
    }

    try {
        for(let question of questions) {
            valid = valid && question.querySelectorAll('input:checked').length > 0;
        }
    } catch (error) {
        console.error(error);
        valid = false;
    }

    return valid;
}

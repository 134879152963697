import axios from 'axios';

const template_auth = `<div class="poll-item">
<div class="poll-preview">
    <div class="poll-preview__date">до {{date}}</div>
    <h3 class="poll-preview__title">{{title}}</h3>
    <div class="poll-preview-action">
        <a href="/quiz/{{id}}" class="btn btn_black">{{btn_text}}</a>
    </div>
</div>
</div>`;

const template_anonimous = `<div class="poll-item">
<div class="poll-preview">
    <div class="poll-preview__date">до {{date}}</div>
    <h3 class="poll-preview__title">{{title}}</h3>
    <div class="poll-preview-action">
    <button
        data-redirect-url="/quiz/{{id}}"
        data-type="login-button"
        class="btn btn_black">{{btn_text}}</button>
    </div>
</div>
</div>`;

function str_date(string_date) {
    const date = new Date(Date.parse(string_date));
    // return `${date.getDate()}.${date.getUTCMonth() + 1}.${date.getFullYear()}`;
    return `${('0' + date.getDate()).slice(-2)}.${date.getUTCMonth() + 1}.${date.getFullYear()}`;
}

function make_html(data) {
    let template = data.user_auth ? template_auth : template_anonimous;

    if (data.btn_text == 'Переглянути результати') {
        template = template_auth;
    }

    let html = '';
    for(let poll of data.poll_list) {
        html += template.replace('{{title}}', poll.title)
                        .replace('{{btn_text}}', data.btn_text)
                        .replace('{{id}}', poll.id)
                        .replace('{{date}}', str_date(poll.date_end));
    }
    return html;
}

export default function () {

    const token = document.querySelector('meta[name="csrf-token"]').content;
    axios.defaults.headers.common['X-CSRF-Token'] = token;
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

    const btn = document.querySelector('[data-type="show_more"]');

    if (!btn) {
        return;
    }

    btn.addEventListener('click', (ev) => {
        const target = ev.target;
        axios.get(target.dataset.url)
            .then(function (response) {
                const box = document.querySelector(`.${target.dataset.target}`);
                box.insertAdjacentHTML('beforeend', make_html(response.data));

                // console.log(response.data.next_link);

                if (response.data.next_link) {
                    btn.dataset.url = response.data.next_link;
                } else {
                    btn.parentNode.style.display = 'none';
                }
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            });
    });
}

/* eslint no-console: ["error", { allow: ["warn", "error"] }] */

import send_date from './send_quiz';
import check_quiz from './check_quiz';

export default function() {

    if (!document.querySelector('#quiz')) {
        return;
    }

    const head = document.querySelector('#quiz');
    const history = document.querySelector('.poll-history-graph');
    const h_data = document.querySelector('.history-data');
    const h_data__value = document.querySelector('.history-data__value');
    const actions = document.querySelector('.poll-action');
    const btn_next = document.querySelector('[data-type="next-step"]');
    const btn_send = document.querySelector('[data-type="send-step"]');

    const poll = head.value;
    const question_list = document.querySelector('.question-list');
    const sections = document.querySelectorAll('.question-section');
    const sections_len = sections.length;
    const step = Math.round(100 / sections_len);
    let slide = 1;


    function draw_history(){

        try {
            let val = step * slide;
            if (val >= 98) {
                val = 100;
            }
            h_data.style.width = `${val}%`;
            h_data__value.innerHTML = `${val}%`;
        } catch (error) {
            console.error(error);
        }
    }


    function get_next_step() {
        if (slide < sections_len) {
            question_list.classList.remove(`slide_${slide}`);
            slide += 1;
            question_list.classList.add(`slide_${slide}`);
            draw_history();
            history.scrollIntoView(top);

            if (slide === sections_len) {
                actions.classList.remove('next-step');
                actions.classList.add('send-step');
            }
        }
    }

    if (sections_len > 1) {
        actions.classList.add('next-step');
    } else {
        actions.classList.add('send-step');
    }

    draw_history();


    if (btn_next) {
        btn_next.addEventListener('click', () => {
            if (check_quiz(slide)) {
                get_next_step();
            }
        });
    }

    if (btn_send) {
        btn_send.addEventListener('click', () => {
            if (check_quiz('all')) {
                send_date(poll);
            }
        });
    }

}

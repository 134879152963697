/* eslint no-console: ["error", { allow: ["warn", "error"] }] */

import axios from 'axios';

export default function(id) {

    const action = '/user_vote';
    const token = document.querySelector('meta[name="csrf-token"]').content;
    axios.defaults.headers.common['X-CSRF-Token'] = token;


    const selected = document.querySelectorAll('.question-list input:checked');
    const payload = {
        poll: id,
        data: {},
    };

    const values = payload.data;

    let quest, answer;

    for(let input of selected){
        quest = input.name.replace('q_', '');
        answer = input.value;

        if (!(quest in values)) {
            values[quest] = [];
        }

        values[quest].push(parseInt(answer, 10));
    }

    axios.post(action, {
        payload: JSON.stringify(payload),
    })
    .then(function (response) {
        window.location = response.data.redirect_to;
    })
    .catch(function (error) {
        console.error(error);
    });


}
